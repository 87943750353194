<template>
  <v-container fluid>
    <v-dialog v-model="bankIdDialog" persistent>
      <v-row align="center" justify="center">
        <v-card :width="!$vuetify.display.xs ? '40vw' : '80vw'">
          <v-card-title>
            <p class="bold-h3">BankID</p>
          </v-card-title>
          <v-row align="center" justify="center">
            <div v-if="bankid.status == 'pending'">
              <v-col
                align="center"
                justify="center"
                v-if="bankid.hintCode == 'outstandingTransaction'"
              >
                <v-col cols="12">
                  <qrcode-vue
                    :value="qrData"
                    level="H"
                    render-as="svg"
                    :size="200"
                  />
                </v-col>
                <v-btn
                  variant="text"
                  class="xl-button text-none"
                  @click="goToBankID()"
                  >{{ $t("open_bankid_on_this_device") }}</v-btn
                >

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="text"
                    class="xl-button text-none"
                    color="error"
                    @click="cancelBankId()"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-col>
              <v-col
                align="center"
                justify="center"
                v-else-if="bankid.hintCode == 'userSign'"
              >
                <p class="body-2">{{ $t("complete_bankid_steps") }}</p>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="text"
                    class="xl-button text-none"
                    color="error"
                    @click="cancelBankId()"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-col>
            </div>
            <div v-else-if="bankid.status == 'failed'">
              <v-col align="center" justify="center">
                <p class="body-1">{{ $t("bankid_failed") }}</p>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="text"
                    class="xl-button text-none"
                    color="primary"
                    @click="bankIdDialog = false"
                    >{{ $t("ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-col>
            </div>
          </v-row>
        </v-card>
      </v-row>
    </v-dialog>
    <v-dialog v-model="swishDialog" persistent>
      <v-row align="center" justify="center">
        <v-card class="pa-15" :width="!$vuetify.display.xs ? '40vw' : '80vw'">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" v-if="swishError == false">
              <v-col justify="center" align="center">
                <div style="position: relative; display: inline-block">
                  <img :src="swishPNG" class="header-image" height="300" />
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="pa-0"
                    :size="50"
                    :width="4"
                    style="
                      position: absolute;
                      top: 55%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      z-index: 1000;
                    "
                  ></v-progress-circular>
                </div>
              </v-col>
              <v-col>
                <p class="body-2">{{ $t("complete_swish_steps") }}</p>
              </v-col>

              <v-col>
                <v-btn color="error" variant="text" @click="cancelSwish">{{
                  $t("cancel_swish")
                }}</v-btn>
              </v-col>
            </v-col>
            <v-col align="center" justify="center" v-else>
              <v-col>
                <p class="text-h4">OBS</p>
              </v-col>
              <v-col>
                <p class="text-h6">{{ swishErrorText }}</p>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  @click="doneSwish"
                  flat
                  class="button none-text"
                  >Okej</v-btn
                >
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-dialog>
    <!-- Content Container -->
    <v-row justify="center" align="center">
      <v-col sm="8" md="6">
        <v-btn
          variant="text"
          class="xl-button text-none"
          color="primary"
          @click="goBackToCart"
          >{{ $t("back_to_cart") }}</v-btn
        >
        <v-col cols="12">
          <p class="bold-h1">{{ $t("checkout") }}</p>
          <!-- Divider below the title -->

          <div class="mt-2">
            <hr class="divider-line" />
          </div>
          <p class="bold-h2">{{ $t("pickup_time") }}</p>
          <v-col cols="12" class="pa-0">
            <v-btn
              color="primary"
              class="xl-button text-none"
              @click="openLocationSelection"
              variant="outlined"
              block
              size="x-large"
            >
              <v-icon>mdi-truck</v-icon>
              <p class="ml-2">
                {{
                  cart.pickupTime != null
                    ? getDayLabel(cart.pickupTime.pickupAt, false) +
                      " " +
                      formatTime(cart.pickupTime.pickupAt) +
                      " - " +
                      formatTime(cart.pickupTime.pickupBefore)
                    : $t("select_pickup_location")
                }}
              </p>
            </v-btn>
          </v-col>
          <v-col>
            <v-row justify="space-between" class="bold-h4">
              <span>{{ $t("item_cost") }}</span>
              <span>{{ productsCost }} kr</span>
            </v-row>
            <v-row justify="space-between" class="bold-h4">
              <v-row class="pa-3">
                <p>{{ $t("reserved") }}</p>
                <v-tooltip :text="$t('reserved_price_note')" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-icon size="small" class="hover pt-2 pl-2" v-bind="props"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                </v-tooltip>
              </v-row>
              <p>{{ reservedAmount }} kr</p>
            </v-row>
            <v-row justify="space-between" class="bold-h4">
              <p>{{ $t("delivery_cost") }}</p>
              <p>{{ deliveryFee }} kr</p>
            </v-row>
          </v-col>
          <!--
          <h2 class="section-title">Varor:</h2>
          <div class="cart-items-container">
            <div
              class="cart-item"
              v-for="(variant, index) in cartItems.productVariants"
              :key="index"
            >
              <div class="item-left">
                <div class="product-image-container">
                  <img
                    :src="variant.productVariant.thumbnail.url"
                    alt="Product Image"
                    class="product-image"
                  />
                </div>
                <div class="item-text-container">
                  <div class="item-name">
                    {{ variant.productVariant.name }}
                  </div>
                  <div class="item-price">
                    {{ variant.productVariant.price }} SEK
                  </div>
                </div>
              </div>
              <div class="item-right">
                <div class="item-amount-container">
                  <div class="item-amount">{{ variant.quantity }} st</div>
                </div>
              </div>
            </div>
          </div>
          -->
          <p class="bold-h3 pt-5">{{ $t("discount_code") }}</p>
          <v-form
            @submit.prevent="applyPromoCode"
            v-if="cart.appliedPromotion == null"
          >
            <v-row align="center" class="pt-4">
              <v-col>
                <v-text-field
                  v-model="promoCode"
                  :placeholder="$t('yes_here_is_my_code')"
                  color="primary"
                  variant="outlined"
                  single-line
                  hide-details
                  class="promo-code-field"
                >
                </v-text-field>
              </v-col>

              <v-btn
                color="primary"
                size="x-large"
                class="xl-button text-none"
                :disabled="promoCode == '' || isProcessing"
                :loading="loadingPromo"
                type="submit"
                >{{ $t("activate") }}</v-btn
              >
            </v-row></v-form
          >
          <v-row v-else align="center" justify="space-between" class="pa-4">
            <p class="bold-h4" style="color: #c94631">
              {{
                $t("discount_cart", {
                  code: cart.appliedPromotion.code,
                  price: cart.price.cartDiscount.toFixed(2),
                })
              }}
            </p>

            <v-btn
              class="button text-none"
              flat
              @click="removePromo"
              :loading="loadingPromo"
              :disabled="loadingPromo || isProcessing"
              >Ta bort</v-btn
            >
          </v-row>
          <v-col>
            <v-row justify="space-between" class="bold-h3 pt-5">
              <span>{{
                $t("total") +
                " (" +
                $t("items_count", { count: totalCartItems }) +
                ")"
              }}</span>
              <span>{{ totalCartPrice }} kr</span>
            </v-row>
            <v-row
              justify="space-between"
              class="bold-h3"
              style="color: #c94631"
            >
              <span>{{ $t("total_discount") }}</span>
              <span>{{ totalDiscount }} kr</span>
            </v-row>
          </v-col>
          <!-- Payment Method Section -->
          <p class="bold-h2 pt-5">{{ $t("choose_payment_method") }}</p>
          <v-col cols="12" align="center" justify="start" class="pa-0">
            <v-col class="pa-0">
              <v-card
                :style="
                  paymentMethod === 'swish'
                    ? 'border: 2px solid #003A70;'
                    : 'border: 0px solid white;'
                "
                class="pa-2"
                :ripple="false"
                @click="paymentMethod = 'swish'"
                elevation="0"
                color="surface"
                ><v-col align="center" justify="center">
                  <v-row>
                    <v-col cols="3" sm="2" align="start" justify="center">
                      <v-card
                        :model-value="paymentMethod === 'swish'"
                        :color="
                          paymentMethod === 'swish' ? 'success' : 'surface'
                        "
                        :style="
                          paymentMethod !== 'swish'
                            ? 'border: 2px solid grey;'
                            : 'border: 0px solid white;'
                        "
                        height="35"
                        width="35"
                        class="rounded-circle no-transition"
                        elevation="0"
                      >
                        <v-col
                          class="pa-1"
                          align="center"
                          justify="center"
                          v-if="paymentMethod === 'swish'"
                        >
                          <v-icon size="small">mdi-check</v-icon></v-col
                        >
                      </v-card>
                    </v-col>
                    <v-row align="center" justify="start">
                      <v-col sm="2" class="pa-0">
                        <v-img
                          src="../../assets/images/Swish_logo.png"
                          class="header-image"
                          alt="Swish Logo"
                        ></v-img>
                      </v-col>
                      <v-col>
                        <p class="body-1 pl-1" align="start" justify="center">
                          Swish
                        </p>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-col v-if="paymentMethod === 'swish'" class="mt-4">
                    <v-phone-input
                      v-model="phone"
                      single-line
                      defaultCountry="SE"
                      :label="$t('phone_number')"
                      :country-label="$t('country')"
                      color="primary"
                      :rules="textFieldRules"
                      validate-on="submit"
                      variant="outlined"
                      country-icon-mode="svg"
                    />
                  </v-col>
                </v-col>
              </v-card>
            </v-col>

            <v-col class="pa-0 pt-4">
              <v-card
                :style="
                  paymentMethod === 'card'
                    ? 'border: 2px solid #003A70;'
                    : 'border: 0px solid white;'
                "
                class="pa-2"
                :ripple="false"
                @click="paymentMethod == 'card' ? null : initializeStripe()"
                elevation="0"
                color="surface"
                align="center"
                justify="center"
              >
                <v-col align-self="center" justify-self="center">
                  <v-row cols="12">
                    <v-col cols="3" sm="2" align="start" justify="center">
                      <v-card
                        :model-value="paymentMethod === 'card'"
                        :color="
                          paymentMethod === 'card' ? 'success' : 'surface'
                        "
                        :style="
                          paymentMethod !== 'card'
                            ? 'border: 2px solid grey;'
                            : 'border: 0px solid white;'
                        "
                        height="35"
                        width="35"
                        class="rounded-circle no-transition"
                        elevation="0"
                      >
                        <v-col
                          class="pa-1"
                          align="center"
                          justify="center"
                          v-if="paymentMethod === 'card'"
                        >
                          <v-icon size="small">mdi-check</v-icon></v-col
                        >
                      </v-card>
                    </v-col>

                    <v-row align="center" justify="start">
                      <v-col sm="2" class="pa-0">
                        <v-img
                          src="../../assets/images/cards.png"
                          class="header-image"
                          alt="Credit Cards Logo"
                        ></v-img>
                      </v-col>
                      <v-col>
                        <p class="body-1 pl-1" align="start" justify="center">
                          {{ $t("card_payment") }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-row>

                  <div
                    id="payment-element"
                    v-if="paymentMethod === 'card'"
                  ></div>
                </v-col>
              </v-card>
            </v-col>
          </v-col>
          <v-row align="center" justify="center">
            <v-btn
              :loading="isProcessing"
              :disabled="isProcessing || loadingPromo"
              color="success"
              elevation="0"
              class="mt-8 xl-button text-none"
              size="x-large"
              @click="handleSubmit"
            >
              {{ $t("confirm_and_pay") }}</v-btn
            >
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue";
import { mapActions, mapState } from "vuex";
import PaymentAPI from "@/api/PaymentAPI";
import { onMounted } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { formatTime, getDayLabel } from "../../utils.js";
import store from "@/store.js"; // Import the Vuex store
import { useI18n } from "vue-i18n";
import swishPNG from "../../assets/images/Swish_phone.png";
import axios from "axios"; // Ensure you have axios imported
import QrcodeVue, { QrcodeCanvas, QrcodeSvg } from "qrcode.vue";
export default {
  name: "SwishPaymentPage",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    Header,
    QrcodeVue,
    QrcodeCanvas,
    QrcodeSvg,
  },
  data() {
    return {
      textFieldRules: [
        (value) => {
          if (value) return true;
          return this.t("field_required");
        },
      ],
      paymentMethod: "swish",
      publishableKey: process.env.VITE_APP_STRIPE_KEY,
      loadingPromo: false,
      stripe: {},
      elements: {},
      swishPNG: swishPNG,
      paymentElement: {},
      bankIdDialog: false,
      isProcessing: false,
      swishErrorMessages: {
        FF08: "Betalningsreferensen är ogiltig. Vänligen kontrollera och försök igen.",
        RP03: "Callback-URL saknas eller använder inte HTTPS. Vänligen kontrollera och försök igen.",
        BE18: "Ditt alias är ogiltigt. Vänligen kontrollera och försök igen.",
        RP01: "Merchant Swish-nummer saknas. Vänligen fyll i det och försök igen.",
        PA02: "Beloppsvärde saknas eller är inte ett giltigt nummer. Vänligen kontrollera och försök igen.",
        AM02: "Beloppsvärdet är för stort. Vänligen minska beloppet och försök igen.",
        AM03: "Ogiltig eller saknad valuta. Vänligen kontrollera och försök igen.",
        AM06: "Transaktionsbeloppet är mindre än överenskommet minimum. Vänligen justera beloppet och försök igen.",
        RP02: "Meddelandet är felaktigt formaterat. Vänligen kontrollera och försök igen.",
        RP06: "En betalningsförfrågan finns redan för dig. Detta gäller endast för Swish e-handel. Vänligen kontrollera och försök igen.",
        ACMT03:
          "Du är inte registrerad. Vänligen registrera dig och försök igen.",
        ACMT01:
          "Motparten är inte aktiverad. Vänligen kontrollera och försök igen.",
        ACMT07:
          "Mottagaren är inte registrerad. Vänligen kontrollera och försök igen.",
        VR01: "Du uppfyller inte åldersgränsen. Vänligen kontrollera och försök igen.",
        VR02: "Ditt alias i förfrågan är inte registrerat i Swish med det angivna personnumret. Vänligen kontrollera och försök igen.",
        RF07: "Transaktionen nekades. Betalningen nekades tyvärr. En anledning kan vara att du har överskridit din definierade Swish-gräns eller har otillräckliga medel på kontot. Vänligen kontrollera med din bank.",
        RP09: "Det angivna instructionUUID är inte tillgängligt. Observera: instructionUUID finns redan i databasen, dvs. det är inte unikt. Vänligen kontrollera och försök igen.",
      },
      paymentIntent: undefined,
      swishDialog: false,
      swishError: false,
      swishErrorText: "",
      removePromoLoading: false,
      addPromoLoading: false,
      timer: {},
      promoCode: "",
      qrData: "",
      continueLoop: true,
      bankid: {},
      phone: "+46", // Default payment method
    };
  },

  unmounted() {
    clearInterval(this.timer);
    this.setCategoryModal(true);
  },
  async mounted() {
    // Create Stripe elements with Swedish local
    this.stripe = await loadStripe(this.publishableKey, {
      betas: ["elements_customers_beta_1"],
      apiVersion: "2019-11-05",
    });

    this.phone = store.state.user.phone;
  },
  watch: {
    locationModalIsVisible(newVal, oldVal) {
      if (
        this.tryingToCheckout == true &&
        newVal == false &&
        oldVal == true &&
        this.cart.pickupTime != null
      ) {
        this.tryingToCheckout == false;

        this.handleSubmit();
      }
    },
  },
  methods: {
    formatTime,
    getDayLabel,
    openLocationSelection() {
      this.toggleLocationModal();
    },
    ...mapActions([
      "reserveCart",
      "unReserveCart",
      "createCart",
      "setCategoryModal",
      "validatePromoCode",
      "removePromoCode",
      "toggleLocationModal",
      "cartPurchased",
    ]),
    goBackToCart() {
      this.$router.push({ name: "cart" });
    },
    async removePromo() {
      this.loadingPromo = true;
      // Logic to apply promo code can be added here

      await this.removePromoCode();
      if (this.paymentIntent != undefined) {
        await PaymentAPI.updateStripePayment(this.paymentIntent.intent);
      }
      this.loadingPromo = false;
    },
    async applyPromoCode() {
      try {
        this.loadingPromo = true;
        // Logic to apply promo code can be added here

        await this.validatePromoCode({ code: this.promoCode });
        if (this.paymentIntent != undefined) {
          await PaymentAPI.updateStripePayment(this.paymentIntent.intent);
        }
        this.loadingPromo = false;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";

          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        } else {
          this.$store.dispatch("vtoast/showToast", {
            message: this.t("code_not_valid"),
            color: "error",
          });
        }

        this.loadingPromo = false;
      }
    },
    async sendCodePhoneNumber() {
      await PaymentAPI.setupSwishPayment({
        payerAlias: this.phone.replace(/^\+/, ""),
      });
    },
    async doneSwish() {
      this.swishDialog = false;
      setTimeout(() => {
        this.swishError = false;
        this.swishErrorText = "";
        this.isProcessing = false;
      }, 1000);
    },
    async cancelSwish() {
      clearInterval(this.timer);
      await PaymentAPI.cancelSwishPayment();
      this.swishDialog = false;
      setTimeout(() => {
        this.swishError = false;
        this.swishErrorText = "";
        this.isProcessing = false;
      }, 1000);
    },
    async cancelBankId() {
      this.bankid = await PaymentAPI.cancelBankid();
      this.bankIdDialog = false;
    },
    goToBankID() {
      const width = window.innerWidth;
      if (width <= 600) {
        window.open(
          `https://app.bankid.com/?autostarttoken=${this.bankid.signResponse.autoStartToken}&redirect=`,
          "_blank"
        );
      } else {
        window.location.href = `bankid:///?autostarttoken=${this.bankid.signResponse.autoStartToken}&redirect=`;
      }
    },
    async handleSubmit() {
      try {
        this.isProcessing = true;
        this.tryingToCheckout = true;
        if (this.cart.pickupTime == null) {
          this.toggleLocationModal();
          return;
        }
        await this.reserveCart();

        // Confirm the payment with Stripe
        if (this.paymentMethod == "card") {
          if (this.cart.minAge > 0) {
            this.bankid = await PaymentAPI.signBankid();
            this.qrData = this.bankid.qrData;
            this.bankIdDialog = true;
            // Function to handle the asynchronous QR data generation loop
            const fetchQrDataLoop = async () => {
              this.continueLoop = true; // You can change this to false to stop the loop when needed

              while (this.continueLoop) {
                this.bankid = await PaymentAPI.fetchBankid();
                this.qrData = this.bankid.qrData;
                switch (this.bankid.status) {
                  case "pending":
                    break;
                  case "failed":
                    throw Error("failed_bankid");
                  case "complete":
                    this.bankIdDialog = false;
                    this.continueLoop = false;
                    break;
                  default:
                    throw Error("failed_bankid");
                }

                // Wait for 1 second before continuing to the next iteration
                await new Promise((resolve) => setTimeout(resolve, 1000));
              }
            };
            // Await the completion of the QR data generation loop
            await fetchQrDataLoop();
          }

          const { error, paymentIntent } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url:
                process.env.VITE_APP_FIREBASE_DEVELOPMENT == "production"
                  ? "https://nearbystore.se/handla/orders"
                  : "https://dev.nearbystore.se/handla/orders",
            },
            redirect: "if_required",
          });
          if (error) {
            console.error("Error confirming payment:", error);
            await this.unReserveCart();
            this.isProcessing = false;
            this.tryingToCheckout = false;
          } else if (
            paymentIntent &&
            (paymentIntent.status === "succeeded" ||
              paymentIntent.status === "requires_capture")
          ) {
            this.isProcessing = false;
            this.$router.push({ name: "orders" });
            this.cartPurchased();
            this.tryingToCheckout = false;
          } else {
            await this.unReserveCart();
            this.isProcessing = false;
            this.tryingToCheckout = false;
          }
        } else {
          await this.sendCodePhoneNumber();
          this.swishDialog = true;
          this.timer = setInterval(async () => {
            const swish = await PaymentAPI.getSwishPayment();

            if (swish.status == "PAID") {
              clearInterval(this.timer);
              this.isProcessing = false;
              this.$router.push({ name: "orders" });
              this.swishDialog = false;
              this.cartPurchased();
              this.tryingToCheckout = false;
            } else if (swish.status == "DECLINED") {
              this.isProcessing = false;
              this.tryingToCheckout = false;
              clearInterval(this.timer);

              this.swishErrorText =
                this.swishErrorMessages[swish.errorCode] ||
                this.t("error_occurred");

              this.swishError = true;
              await this.unReserveCart();
            } else if (swish.status == "CANCELLED") {
              this.swishDialog = false;
              this.swishErrorText =
                this.swishErrorMessages[swish.errorCode] ||
                this.t("error_occurred");
              this.isProcessing = false;
              this.tryingToCheckout = false;
              clearInterval(this.timer);
              await this.unReserveCart();
            } else if (swish.status == "ERROR") {
              this.isProcessing = false;
              this.tryingToCheckout = false;
              clearInterval(this.timer);

              this.swishErrorText =
                this.swishErrorMessages[swish.errorCode] ||
                this.t("error_occurred");
              this.swishError = true;
              await this.unReserveCart();
            }
          }, 5000);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";
          if (reason == "promotion-error") {
            this.promoCode = "";
          }
          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        }
        this.tryingToCheckout = false;
        await this.unReserveCart();
        this.isProcessing = false;
      }
    },
    async initializeStripe() {
      try {
        this.paymentMethod = "card";

        if (this.paymentIntent == undefined) {
          // Fetch the client secret from the server
          this.paymentIntent = await PaymentAPI.setupStripePayment();

          // Set loading state to false
        } else {
          await setTimeout(50);
        }

        const loader = "auto";
        this.elements = this.stripe.elements({
          customerOptions: {
            customer: store.state.user.stripeId,
            ephemeralKey: this.paymentIntent.ephemeralKey,
          },
          loader,
          allow_redisplay: "always",
          clientSecret: this.paymentIntent.client_secret,
          locale: this.currentLang,
        });
        // Create and mount the payment element
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount("#payment-element");
      } catch (error) {
        // Handle errors during Stripe initialization
        console.error("Error initializing Stripe:", error);
      }
    },
  },
  computed: {
    // Map state from Vuex store
    ...mapState(["cart", "currentLang", "locationModalIsVisible"]),
    // Calculate total cart price
    totalCartPrice() {
      return this.cart.price.total.toFixed(2);
    },
    // Calculate total number of items in the cart
    totalCartItems() {
      return this.cart.totalItems;
    },
    // Calculate reserved amount
    reservedAmount() {
      return (
        this.cart.price.reservedBags + this.cart.price.reservedWeight
      ).toFixed(2);
    },
    // Calculate delivery fee
    deliveryFee() {
      return this.cart.price.deliveryFee.toFixed(2);
    },
    // Calculate products cost
    productsCost() {
      return this.cart.price.products.toFixed(2);
    },
    // Calculate total discount
    totalDiscount() {
      return (
        this.cart.price.productDiscount + this.cart.price.cartDiscount
      ).toFixed(2);
    },
  },
};
</script>

<style scoped>
.card-disabled {
  pointer-events: none;
  position: relative;
  /* Add any other styles you want to apply to the disabled state */
}
.no-transition {
  transition: none;
}
.v-main {
  background-color: #f0f0f0;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.checkout-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 75%;
  overflow-y: hidden;
  position: absolute;
}

.left-column {
  height: 100%;
  padding: 35px;
  border-right: 1px solid #ccc;
}

.left-column-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cart-items-container {
  overflow-y: auto;
}

.right-column {
  padding: 60px;
  padding-top: 35px;
}

/* Divider styles */
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.divider .divider-line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
}

/* Divider with text styles */
.divider-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  /* Center vertically with margin from the middle */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 70px; /* 50px line height + 20px margin-top */
}

.divider-with-text .divider-line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
}

.divider-with-text .divider-text {
  margin: 0 10px;
  color: #333;
  font-weight: bold;
}

#payment-element {
  padding: 10px;
  border-radius: 4px;
  margin-top: 16px;
  border: 1px solid #ccc;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.title {
  line-height: 50px;
}

.section-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.item-left {
  display: flex;
  flex-direction: row;
}

.item-right {
  display: flex;
}

.product-image-container {
  display: flex;
  align-items: center;
}

.product-image {
  height: 75px;
  width: 100px;
  object-fit: contain;
}

.item-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-left: 20px;
  max-width: 50%;
}

.item-name {
  font-weight: bold;
}

.item-price {
  color: #333;
  margin-top: 15px;
}

.item-amount-container {
  user-select: none;
  display: flex;
  font-size: 18px;
  align-items: center;
}

.item-amount {
  min-width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.price-summary {
  margin-bottom: 20px;
}

.total-summary {
  margin-top: 35px;
  font-size: 18px;
  font-weight: bold;
}

.discount-summary {
  margin-top: 5px;
  color: red;
}

.price-item,
.total-summary,
.discount-summary {
  display: flex;
  justify-content: space-between;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.checkout-btn {
  background-color: #003a70;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  self-align: center;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-btn:hover {
  background-color: #005092;
}
</style>
